import React from 'react';
import { Link, graphql } from 'gatsby';
import GridContainer from '../layout/Grid/GridContainer.js';
import GridRow from '../layout/Grid/GridRow';
import GridColumn from '../layout/Grid/GridColumn';
import Section from '../layout/Section.js';
import SEO from '../components/SEO.js';
import IntroSection from '../components/IntroSection.js';
import Hero from '../components/Hero.js';

export default function GenderPage(props) {
  return (
    <>
      <SEO title={props.data.page.headline} />

      <Hero hero={props.data.page} />

      <Section>
        <GridContainer>
          <GridRow>
            <GridColumn start="3" span="8">
              <IntroSection intro={props.data.page} />
              <Link to="../" className="mt-2">
                Zur Startseite
              </Link>
            </GridColumn>
          </GridRow>
        </GridContainer>
      </Section>
    </>
  );
}

export const query = graphql`
  query genderQuery {
    page: sanityMainPages(slug: { current: { eq: "gender" } }) {
      headline
      seoTitle
      seoDescription
      _rawIntroCopy
      heroHeadline
      enableHero
      heroImage {
        alt
        asset {
          fluid(maxWidth: 1920) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`;
